import CrudService from "../core/crud-service";

export default class RecordingsService extends CrudService {
  static route = "api/v1/recordings";

  async getPublicRecordingByFilename(model: { fileName: string }) {
    return await this.httpService.get<any>(`${this.path}/${model.fileName}`);
  }

  async getDownloadLink(model: { fileName: string }) {
    return await this.httpService.get<any>(
      `${this.path}/${model.fileName}/download`
    );
  }
}
