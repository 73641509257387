import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import { ROLE } from "../../../types";

export class AppStore {
  listSpaces = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.list();
  };

  listSpaceRooms = async (spaceId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.listRoomsBySpaceId(spaceId);
  };

  deleteMember = async (id: number) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.deleteMember(id);
  };

  getMeetingByRoomId = async (id: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.roomsService.getMeetingByRoomId(id);
  };

  deleteInvite = async (id: number) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.deleteInvite(id);
  };

  listMembersBySpace = async (spaceId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.listMembersBySpace(spaceId);
  };

  listInvitesBySpace = async (spaceId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.listInvitesBySpace(spaceId);
  };

  getSpacePublicAuth = async (spaceId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.getSpacePublicAuth(spaceId);
  };

  getSpacePublicNotAuth = async (spaceId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.getSpacePublicNotAuth(spaceId);
  };

  listRelatedSpaces = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.relatedSpacesService.list();
  };

  updateSpace = async (model: {
    id: number;
    name: string;
    password?: string | null;
    welcomePageEnabled?: boolean;
    welcomePageName?: string;
    welcomePageDescription?: string;
    welcomePageDate?: string;
    welcomePageBackground?: string | null;
    logo: string | null;
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.updateSpace(model);
  };

  validatePassword = async (model: { roomId: string; password: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.roomsService.validatePassword(model);
  };

  createSpace = async (model: {
    name: string;
    password?: string | null;
    welcomePageEnabled?: boolean;
    welcomePageName?: string;
    welcomePageDescription?: string;
    welcomePageDate?: string;
    welcomePageBackground?: string | null;
    logo: string | null;
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.createSpace(model);
  };

  deleteSpace = async (model: { id: number }) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.deleteSpace(model);
  };

  setRole = async (model: { role: ROLE; spaceId: string; rooms?: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.setRole(model);
  };

  findInviteByCode = async (model: { code: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.invitesService.findByCode(model.code);
  };

  statBySpaceId = async (model: { spaceId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.spaceJoinsService.countBySpaceId(model.spaceId);
  };

  getCheckoutLink = async (model: { planId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.billingService.getCheckoutLink(model.planId);
  };

  getCustomerPortalLink = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.billingService.getCustomerPortalLink();
  };

  getKeynotes = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.plotService.getByUser();
  };

  getKeynotePermission = async (model: {
    plotId: string;
    email: string | null;
    password?: string;
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.plotService.getKeynotePermission(model);
  };

  createPlot = async (model: { name: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.plotService.createPlot(model);
  };

  listPexelsBackgrounds = async (query: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.roomsService.listPexelsBackgrounds(query);
  };

  getPublicRecordingByFilename = async (model: { fileName: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.recordingsService.getPublicRecordingByFilename(model);
  };

  getDownloadLink = async (model: { fileName: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.recordingsService.getDownloadLink(model);
  };
}
