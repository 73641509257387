/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from "react";
import { Modal, createStyles } from "@mantine/core";
// import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { useDisclosure } from "@mantine/hooks";
// import VideoRecorder from 'react-video-recorder'
// import { VideoUploader } from '@api.video/video-uploader'
// import { ProgressiveUploader } from '@api.video/video-uploader'
// import ApiVideoPlayer from '@api.video/react-player'
import { useWindowSize } from "../../../helpers/helper";
import {
  Player,
  Video,
  DefaultUi,
  Scrim,
  Controls,
  ControlSpacer,
  MuteControl,
  PlaybackControl,
  TimeProgress,
} from "@vime/react";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";

interface ButtonProps {
  width: number;
  height: number;
}

const container = {
  delay: 1.8,
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const useStyles = createStyles((theme, { width, height }: ButtonProps) => ({
  container: {
    height: "100vh",
    width: "100%",
    // background: "white",
    display: "flex",
    alignItems: "center",
    // background: '#ffffff',

    background: "#abbaab" /* fallback for old browsers */,
    // background: '-webkit-linear-gradient(to right, #abbaab, #ffffff)', /* Chrome 10-25, Safari 5.1-6 */
    backgroundImage:
      "linear-gradient(to left, #abbaab, #ffffff)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    // backgroundColor: "#FAD961",
    // backgroundImage: "linear-gradient(135deg, #FAD961 0%, #F76B1C 100%)",

    // backgroundColor: '#A9C9FF',
    // backgroundImage: 'linear-gradient(162deg, #A9C9FF 0%, #FFBBEC 100%)',

    // backgroundImage: 'linear-gradient(to bottom,  rgba(54, 54, 54, 0.8), rgba(0, 0, 0, 0.9)), url("https://vod.api.video/vod/vi3gitF5AbbwQo2EXGUD7hhQ/thumbnail.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    // backdropFilter: 'blur(20px)'
    // filter: 'blur(8px)',
    // '-webkit-filter': 'blur(8px)'
  },
  recorder: {
    width: "300px",
    height: "600px",
  },
  video: {
    margin: "0 auto",
    width: `400px`,
    height: "711px",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      // top: '0',
      // left: '-50%',
      transform: "scale(0.5)",
      width: "800px",
      height: "1422px",
      borderRadius: "32px",
      overflow: "hidden",
    },
  },

  container1: {
    width: "100%",
    height: "250px",
    display: "grid",
    overflow: "hidden",
    margin: "0",
    listStyle: "none",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    padding: "15px",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "6px",
  },
  textContainer: {
    width: "100%",
    display: "grid",
    overflow: "hidden",
    margin: "0",
    listStyle: "none",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    padding: "0 15px",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "6px",
    color: "white",
  },
  h2: {
    textAlign: "center",
  },
  imageSkillbox: {
    // borderRadius: "20px",
    height: "42px",
    width: "140px",
    margin: "20px auto 0 auto",
  },

  item1: {
    background: "white",
    borderRadius: "6px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",

    ":hover": {
      background: "#ffffffd1",
    },
  },

  container2: {
    margin: "60px auto",
    display: "flex",
    alignItems: "center",
  },
  video2: {
    // margin: '60px auto',
    width: `calc(100vh * 0.5625 - 60px )`,
    // borderRadius: '32px',
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',
  },
  caption: {
    zIndex: 1,
    maxWidth: `400px`,
    margin: "0 30px",
    // borderRadius: '32px',
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',
  },
}));
const VideoFlow = () => {
  // const {
  //   dataStore: { spaceStore },
  // } = useStore();

  const { width, height } = useWindowSize();

  const player = useRef<HTMLVmPlayerElement>(null);

  // 9:16

  const w = width as any as number;
  const h = height as any as number;
  if (w > (h * 16) / 9) {
    // cosnt minSize = h
    console.log("w");
  } else {
    console.log("h");
  }

  const { classes } = useStyles({
    width: width as any as number,
    height: height as any as number,
  });

  const [preview, setPreview] = useState<boolean>(true);
  const [editedWidth, setEditedWidth] = useState<number>(0);

  const [opened, { open, close }] = useDisclosure(false);

  // const videoId = 'vi3gitF5AbbwQo2EXGUD7hhQ'

  if (preview) {
    return (
      <>
        <div className={classes.container}>
          <div className={classes.overlay}></div>

          <div className={classes.container2}>
            <div className={classes.video2}>
              <motion.div
                className="box"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <Player
                  ref={player}
                  theme="dark"
                  aspectRatio={"9:16"}
                  // @ts-ignore
                  style={{
                    // @ts-ignore
                    "--vm-player-theme": "#e86c8b",
                    "--vm-player-border-radius": "14px",
                  }}
                  autoplay={true}
                  playsinline={true}
                  muted={false}
                  loop={true}
                  controls={false}
                  onVmPlay={() => {
                    if (editedWidth === 0) {
                      setEditedWidth(1);
                    } else {
                      // setEditedWidth(0)
                    }
                  }}
                >
                  <Video
                    // @ts-ignore
                    crossOrigin
                    // poster="https://vod.api.video/vod/vi3gitF5AbbwQo2EXGUD7hhQ/thumbnail.jpg"
                  >
                    <source data-src="./2.mp4" type="video/mp4" />
                  </Video>

                  <DefaultUi noControls>
                    <Scrim />

                    <Controls fullWidth pin="topLeft">
                      <ControlSpacer />
                      <MuteControl />
                    </Controls>

                    <Controls pin="center">
                      <PlaybackControl
                        // @ts-ignore
                        style={{ "--vm-control-scale": 1.7, margin: "0 auto" }}
                      />
                    </Controls>

                    <Controls fullWidth pin="bottomLeft">
                      <ControlSpacer />
                      <TimeProgress />
                    </Controls>
                  </DefaultUi>
                </Player>
              </motion.div>
            </div>
            <motion.div
              className={classes.caption}
              style={{ overflow: "hidden" }}
              initial={{ width: editedWidth, opacity: editedWidth }}
              animate={{
                width: editedWidth ? "400px" : 0,
                opacity: editedWidth ? 1 : 0,
              }}
              layout
              transition={{
                type: "spring",
                duration: 1,
                // stiffness: 100,
              }}
            >
              <motion.div
                className={classes.textContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: "spring",
                  duration: 0.8,
                  delay: 1.8,
                  // ease: [0, 0.71, 0.2, 1.01]
                }}
              >
                <img
                  alt=""
                  src={"./download.png"}
                  className={classes.imageSkillbox}
                />
              </motion.div>
              <motion.div
                className={classes.textContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: "spring",
                  duration: 0.8,
                  delay: 1.8,
                  // ease: [0, 0.71, 0.2, 1.01]
                }}
              >
                <h2 className={classes.h2}>Supreme Shop</h2>
              </motion.div>

              {editedWidth && (
                <motion.ul
                  className={classes.container1}
                  variants={container}
                  initial="hidden"
                  animate="visible"
                >
                  <motion.li key={1} className={classes.item1} variants={item}>
                    Buy sneakers online
                  </motion.li>
                  <motion.li key={2} className={classes.item1} variants={item}>
                    Contact manager
                  </motion.li>
                  <motion.li key={3} className={classes.item1} variants={item}>
                    Reserve
                  </motion.li>
                </motion.ul>
              )}
            </motion.div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: "white",
        display: "flex",
      }}
    >
      <div className={classes.recorder}>
        {/* <VideoRecorder
          constraints={{
            audio: true,
            video: {
              width: 800,
              height: 1200,
            },
          }}
          onRecordingComplete={(videoBlob: Blob) => {
            const uploader = new ProgressiveUploader({
              videoId: videoId,
              uploadToken: "to6b7GezbH9dPv6SNZMT0ahn"
            }).uploadLastPart(videoBlob)

            // Do something with the video...
            console.log('videoBlob', videoBlob)
          }}
        /> */}
      </div>

      {/* <ApiVideoPlayer
    videoStyleTransform=""
    onQualityChange={(props) => {
      console.log(props)
    }}
    videoStyleObjectFit={'cover'}
    chromeless={true}

    video={{ id: videoId }}
    autoplay={true}
    muted={true}
    loop={true}
    onEnded={() => {
      console.log('ended')
    }}
  /> */}

      <Modal opened={opened} onClose={close} title="Select page type"></Modal>
    </div>
  );
};

export default observer(VideoFlow);
