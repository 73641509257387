/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from "react";
import { Modal, createStyles } from "@mantine/core";
// import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { useDisclosure } from "@mantine/hooks";
// import VideoRecorder from 'react-video-recorder'
// import { VideoUploader } from '@api.video/video-uploader'
// import { ProgressiveUploader } from '@api.video/video-uploader'
// import ApiVideoPlayer from '@api.video/react-player'
import { useWindowSize } from "../../../helpers/helper";
import {
  Player,
  Video,
  DefaultUi,
  Scrim,
  Controls,
  ControlSpacer,
  MuteControl,
  PlaybackControl,
  TimeProgress,
} from "@vime/react";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from "react-helmet";

interface ButtonProps {
  width: number;
  height: number;
}

const container = {
  delay: 1.8,
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const useStyles = createStyles((theme, { width, height }: ButtonProps) => ({
  container: {
    height: "100vh",
    width: "100%",
    background: "white",
    display: "flex",
    alignItems: "center",

    // background-color: #8EC5FC;
    // background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

    // background: '#ffffff',
    backgroundColor: "#8EC5FC",
    backgroundImage: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",

    // backgroundColor: '#A9C9FF',
    // backgroundImage: 'linear-gradient(162deg, #A9C9FF 0%, #FFBBEC 100%)',

    // backgroundImage: 'linear-gradient(to bottom,  rgba(54, 54, 54, 0.8), rgba(0, 0, 0, 0.9)), url("https://vod.api.video/vod/vi3gitF5AbbwQo2EXGUD7hhQ/thumbnail.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    // backdropFilter: 'blur(20px)'
    // filter: 'blur(8px)',
    // '-webkit-filter': 'blur(8px)'
  },
  recorder: {
    width: "300px",
    height: "600px",
  },
  video: {
    margin: "0 auto",
    width: `400px`,
    height: "711px",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      // top: '0',
      // left: '-50%',
      transform: "scale(0.5)",
      width: "800px",
      height: "1422px",
      borderRadius: "32px",
      overflow: "hidden",
    },
  },

  container1: {
    width: "100%",
    height: "150px",
    display: "grid",
    overflow: "hidden",
    margin: "0",
    listStyle: "none",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    padding: "15px",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "6px",
  },
  textContainer: {
    width: "100%",
    display: "grid",
    overflow: "hidden",
    margin: "0",
    listStyle: "none",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    padding: "0 15px",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "6px",
    color: "white",
  },
  h2: {
    textAlign: "center",
  },
  imageSkillbox: {
    borderRadius: "20px",
    height: "112px",
    width: "184px",
    margin: "0 auto 0 auto",
  },

  item1: {
    background: "white",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",

    ":hover": {
      background: "#ffffffd1",
    },
  },

  container2: {
    margin: "60px auto",
    display: "flex",
    alignItems: "center",
  },
  video2: {
    // margin: '60px auto',
    width: `calc(100vh * 0.5625 - 60px )`,
    // borderRadius: '32px',
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',
  },
  caption: {
    zIndex: 1,
    maxWidth: `400px`,
    margin: "0 30px",
    // borderRadius: '32px',
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',
  },
}));
const Widget = () => {
  // const {
  //   dataStore: { spaceStore },
  // } = useStore();

  const { width, height } = useWindowSize();

  const player = useRef<HTMLVmPlayerElement>(null);

  // 9:16

  const w = width as any as number;
  const h = height as any as number;
  if (w > (h * 16) / 9) {
    // cosnt minSize = h
    console.log("w");
  } else {
    console.log("h");
  }

  const { classes } = useStyles({
    width: width as any as number,
    height: height as any as number,
  });

  const [preview, setPreview] = useState<boolean>(true);
  const [editedWidth, setEditedWidth] = useState<number>(0);

  const [opened, { open, close }] = useDisclosure(false);

  // const videoId = 'vi3gitF5AbbwQo2EXGUD7hhQ'

  if (preview) {
    return (
      <>
        <div className={classes.container}>
          <div className={classes.overlay}></div>

          <Helmet>
            <script
              src="//code.tidio.co/25mqd9pkqqpudasbtk0pjqt43ve1jaa2.js"
              async
            ></script>
          </Helmet>
        </div>
      </>
    );
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: "white",
        display: "flex",
      }}
    >
      <div className={classes.recorder}>
        {/* <VideoRecorder
          constraints={{
            audio: true,
            video: {
              width: 800,
              height: 1200,
            },
          }}
          onRecordingComplete={(videoBlob: Blob) => {
            const uploader = new ProgressiveUploader({
              videoId: videoId,
              uploadToken: "to6b7GezbH9dPv6SNZMT0ahn"
            }).uploadLastPart(videoBlob)

            // Do something with the video...
            console.log('videoBlob', videoBlob)
          }}
        /> */}
      </div>

      {/* <ApiVideoPlayer
    videoStyleTransform=""
    onQualityChange={(props) => {
      console.log(props)
    }}
    videoStyleObjectFit={'cover'}
    chromeless={true}

    video={{ id: videoId }}
    autoplay={true}
    muted={true}
    loop={true}
    onEnded={() => {
      console.log('ended')
    }}
  /> */}

      <Modal opened={opened} onClose={close} title="Select page type"></Modal>
    </div>
  );
};

export default observer(Widget);
