// import { NOTIFICATION_SERVICE } from "../../components/notifications/NotificationService";
import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";
import { ROLE } from "../../types";

export default class AuthService extends BaseService {
  static route = "api/v1/auth";
  private storage: StorageService = new StorageService();

  storeCredentials(token: string) {
    this.storage.setItem(StorageItems.TOKEN, token);
  }

  clearCredentials() {
    this.storage.clearItem(StorageItems.TOKEN);
  }

  getCredentials(item: StorageItems) {
    return this.storage.getItem(item);
  }

  async loginWithToken() {
    return await this.httpService.post<any, any>(`${this.path}/login`);
  }

  async getProfile() {
    return await this.httpService.get<any>(`${this.path}/me`);
  }

  async login(email: string, password: string): Promise<any> {
    return await this.httpService.post<any, any>(`${this.path}/email/login`, {
      email,
      password,
    });
  }

  async sendInvites(model: { emails: string }): Promise<any> {
    return this.httpService.post<any, any>(`${this.path}/send-invite`, model);
  }

  async sendSpaceInvites(model: {
    emails: string;
    role: ROLE;
    spaceId: string;
    date?: string;
    rooms?: string;
  }): Promise<any> {
    return this.httpService.post<any, any>(
      `${this.path}/send-space-invite`,
      model
    );
  }

  async generateInviteLink(model: {
    role: ROLE;
    spaceId: string;
    date?: string;
    rooms?: string;
  }): Promise<any> {
    return this.httpService.post<any, any>(
      `${this.path}/generate-space-invite`,
      model
    );
  }

  async register(model: {
    firstName: string;
    lastName: string;
    password: string;
    email?: string;
    avatar?: string;
    inviteCode?: string;
  }): Promise<any> {
    let fbpArr = document.cookie
      .split(";")
      .filter((c) => c.includes("_fbp="))
      .map((c) => c.split("_fbp=")[1]);
    let fbcArr = document.cookie
      .split(";")
      .filter((c) => c.includes("_fbc="))
      .map((c) => c.split("_fbc=")[1]);
    let fbp = (fbpArr.length && fbpArr[0]) || null;
    let fbc = (fbcArr.length && fbcArr[0]) || null;

    if (!fbc && window.location.search.includes("fbclid=")) {
      fbc =
        "fb.1." +
        +new Date() +
        "." +
        window.location.search.split("fbclid=")[1];
    }

    return await this.httpService.post<any, any>(
      `${this.path}/email/register`,
      {
        ...model,
        fbp,
        fbc,
      }
    );
  }

  async attachEmail(email: string): Promise<any> {
    return await this.httpService.post<any, any>(
      `${this.path}/email/attach-email`,
      { email }
    );
  }

  async forgotPassword(email: string): Promise<any> {
    return await this.httpService.post<any, any>(
      `${this.path}/forgot/password`,
      { email }
    );
  }

  async resetPassword(password: string, hash: string): Promise<any> {
    return await this.httpService.post<any, any>(
      `${this.path}/reset/password`,
      { hash, password }
    );
  }

  async update(
    avatar: string | null,
    firstName: string,
    lastName: string
  ): Promise<any> {
    return await this.httpService.patch<any, any>(`${this.path}/me`, {
      avatar,
      firstName,
      lastName,
    });
  }

  parseError(error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // if (error.response.data.errors) {
      //   NOTIFICATION_SERVICE.errorNotification(
      //     Object.keys(error.response.data.errors)
      //       .map((key) => error.response.data.errors[key])
      //       .join(", ")
      //   );
      // } else {
      //   NOTIFICATION_SERVICE.errorNotification(error.response.data);
      // }
    } else if (error.request) {
      // NOTIFICATION_SERVICE.errorNotification("Something went wrong, try later");
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // NOTIFICATION_SERVICE.errorNotification("Fatal error");
      // Something happened in setting up the request that triggered an Error
    }
  }
}
