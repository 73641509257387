import React, { useState } from "react";
// import { ConfigProvider } from "antd";
import { observer } from "mobx-react";
// import { useStore } from "./hooks/use-store";
// import LoggedIn from "./pages/routers/logged-in";
// import LoggedOut from "./pages/routers/logged-out";
// import { AuthState } from "./stores/ui/auth-store/auth-store";
// import Header from "./components/header/header";

// order important so we can override antd
// import "antd/dist/reset.css";
import "./App.scss";
// import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Invite from "./features/invite";
// import KickedPage from "./pages/public/kicked/KickedPage";
// import ForbiddenPage from "./pages/public/forbidden/ForbiddenPage";
// import MeetingRouter from "./features/meeting/MeetingRouter";
// import SessionOver from "./pages/public/sessionOver/SessionOver";
// import PublicRecording from "./pages/public/publicRecording/PublicRecording";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
// import CustomNodeFlow from "./features/workspace/flow/Flow";
import India from "./features/workspace/videoFlow/India";
import Sneakers from "./features/workspace/videoFlow/Sneakers";
import Coffee from "./features/workspace/videoFlow/Coffee";
import Widget from "./features/workspace/videoFlow/Widget";

function App() {
  // const rootStore = useStore();
  // const {
  //   uiStore: { authStore },
  // } = rootStore;

  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme, fontFamily: "Outfit" }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Router>
          <Switch>
            {/* <Route path="/meeting/:roomId">
                <MeetingRouter />
              </Route>
              <Route path="/test/">
                <CustomNodeFlow />
              </Route> */}
            <Route path="/india/">
              <India />
            </Route>
            <Route path="/sneakers/">
              <Sneakers />
            </Route>
            <Route path="/">
              <Coffee />
            </Route>
            <Route path="/widget/">
              <Widget />
            </Route>
            {/* <Route path="/kicked/">
                <KickedPage />
              </Route>
              <Route path="/forbidden/">
                <ForbiddenPage />
              </Route>
              <Route path="/end/">
                <SessionOver />
              </Route>
              <Route path="/r/:fileName">
                <PublicRecording />
              </Route> */}
            {/* {authStore.authState === AuthState.LoggedIn ? (
                <LoggedIn />
              ) : (
                <LoggedOut />
              )} */}
          </Switch>
        </Router>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default observer(App);
