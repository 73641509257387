// import { action, makeObservable, observable } from "mobx";
// // import { io, Socket } from "socket.io-client";
// import { getEnv } from "../../../helpers/mobx-easy-wrapper";
// import Element, { Pos, Size } from "./element";
// import { ELEMENT_TYPES } from "../../../helpers/enums";
// import {
//   ElementUpdate,
//   IframeElementData,
//   ImageElementData,
//   RoomElement,
//   TextElementData,
// } from "../../../types";
// import { nanoid } from "nanoid";

export class ElementsStore {}
