// import { action, makeObservable, observable } from "mobx";
// import { getUserIdFromSession } from "../../../helpers/helper";
// import { getEnv } from "../../../helpers/mobx-easy-wrapper";

export type Message = {
  created_at: string;
  id: string;
  text: string;
  user: {
    id: number;
    avatar: string;
    firstName: string;
    lastName: string;
  };
};

export class ChatStore {}
