/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from "react";
import { Modal, createStyles, em, getBreakpointValue } from "@mantine/core";
// import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { useDisclosure } from "@mantine/hooks";
// import VideoRecorder from 'react-video-recorder'
// import { VideoUploader } from '@api.video/video-uploader'
// import { ProgressiveUploader } from '@api.video/video-uploader'
// import ApiVideoPlayer from '@api.video/react-player'
import { useWindowSize } from "../../../helpers/helper";
import {
  Player,
  Video,
  DefaultUi,
  Scrim,
  Controls,
  ControlSpacer,
  MuteControl,
  PlaybackControl,
  TimeProgress,
} from "@vime/react";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";

interface ButtonProps {
  width: number;
  height: number;
}

const container = {
  delay: 1.8,
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const useStyles = createStyles((theme, { width, height }: ButtonProps) => ({
  container: {
    // eslint-disable-next-line
    minHeight: "-webkit-fill-available",
    height: "100%",
    width: "100%",
    background: "white",
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",

    // background-color: #8EC5FC;
    // background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

    // background: '#ffffff',
    backgroundColor: "black",
    // backgroundImage: "linear-gradient(62deg, #221e24 0%, #2a2e26 100%)",

    // backgroundColor: '#A9C9FF',
    // backgroundImage: 'linear-gradient(162deg, #A9C9FF 0%, #FFBBEC 100%)',

    backgroundImage: 'url("./wiqlkodU_2x.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    // backdropFilter: 'blur(20px)'
    // filter: 'blur(8px)',
    // '-webkit-filter': 'blur(8px)'
  },
  recorder: {
    width: "300px",
    height: "600px",
  },

  mainVideo: {},
  video: {
    margin: "0 auto",
    width: `400px`,
    height: "711px",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      // top: '0',
      // left: '-50%',
      transform: "scale(0.5)",
      width: "800px",
      height: "1422px",
      borderRadius: "32px",
      overflow: "hidden",
    },
  },

  container1: {
    width: "calc(100% - 60px)",
    display: "grid",
    overflow: "hidden",
    // margin: "0",
    listStyle: "none",
    background: "rgba(255, 255, 255, 0.2)",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    margin: "0 30px",
    padding: "10px",

    // height: "150px",
    // display: "grid",
    // overflow: "hidden",
    // margin: "0",
    // listStyle: "none",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        gap: "8px",
        margin: "0 auto",
        width: "100%",
      },
  },

  h2: {
    marginTop: "0",
    textAlign: "center",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        fontSize: "16px",
        marginBottom: "0",
        marginTop: "0",
      },
  },
  imageSkillbox: {
    // borderRadius: "20px",
    height: "59px",
    width: "94px",
    margin: "0 auto 0 auto",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        height: "41px",
        width: "64px",
        margin: "0 auto 0 auto",
      },
  },

  item1: {
    height: "50px",
  },

  liButton: {
    borderRadius: "6px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    height: "50px",
    border: "none",
    fontWidth: "900",
    background: "#7311eb",
    color: "#51ff13",
    fontSize: "20px",
    fontFamily: "Outfit",
    textDecoration: "none",
    textShadow: "1px 1px #000000",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        // height: "36px",
        borderRadius: "3px",
        // fontSize: "16px",
      },
  },

  liButton2: {
    borderRadius: "6px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    height: "50px",
    border: "none",
    fontWidth: "900",
    background: "#012dc7",
    color: "white",
    fontSize: "20px",
    fontFamily: "Outfit",

    textShadow: "1px 1px black",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        // height: "50px",
        borderRadius: "3px",
        // fontSize: "20px",
      },
  },

  container2: {
    margin: "60px auto",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        margin: "0 auto",
      },
  },
  video2: {
    // margin: '60px auto',
    width: `calc(100vh * 0.5625 - 66px )`,
    borderRadius: "32px",
    overflow: "hidden",
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',

    // Static media query
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        width: `calc(100vh * 0.43)`,
      },
  },
  caption: {
    zIndex: 1,
    maxWidth: `400px`,
    margin: "0 auto",
    borderRadius: "6px",

    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // height: 'calc( 100vh - 120px )',

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        position: `absolute`,
        // width: `calc(100vh * 0.43)`,
        maxWidth: `calc(100vh * 0.43)`,
        margin: "0 auto",
        bottom: "80px",

        left: "auto",
        right: "auto",
      },
  },
  textContainer: {
    width: "calc(100% - 60px)",
    display: "grid",
    overflow: "hidden",
    // margin: "0",
    listStyle: "none",
    background: "rgba(255, 255, 255, 0.2)",
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridTemplateRows: 'repeat(2, 1fr)',
    gap: "15px",
    margin: "0 30px",
    padding: "0 15px",
    // background: "rgba(255, 255, 255, 0.2)",

    color: "white",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      {
        margin: "0 auto",
        width: "100%",
      },
  },
  footer: {
    position: "fixed",
    bottom: "4px",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
  },
  footerLink: {
    color: "white",
    textDecoration: "none",
    marginLeft: "8px",
    marginRight: "8px",
  },

  cls1: {
    fill: "#fc4c00",
  },

  cls2: {
    fill: "#ffcd03",
  },

  cls3: {
    fill: "#14b9ff",
  },

  cls4: {
    fill: "#1ddb00",
  },

  cls5: {
    fill: "#ffffff",
  },

  cls6: {
    fill: "#1943ff",
  },
  cls7: {
    fill: "#9c5fff",
  },
}));
const VideoFlow = () => {
  // const {
  //   dataStore: { spaceStore },
  // } = useStore();

  const { width, height } = useWindowSize();

  const player = useRef<HTMLVmPlayerElement>(null);

  // 9:16

  const w = width as any as number;
  const h = height as any as number;
  if (w > (h * 16) / 9) {
    // cosnt minSize = h
    console.log("w");
  } else {
    console.log("h");
  }

  const { classes } = useStyles({
    width: width as any as number,
    height: height as any as number,
  });

  const [editedWidth, setEditedWidth] = useState<number>(0);

  const [opened, { open, close }] = useDisclosure(false);

  // const videoId = 'vi3gitF5AbbwQo2EXGUD7hhQ'

  return (
    <div className={classes.container}>
      {/* <div className={classes.overlay}></div> */}

      <div className={classes.container2}>
        <div className={classes.video2}>
          <motion.div
            className="box"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Player
              ref={player}
              theme="dark"
              aspectRatio={"9:16"}
              // @ts-ignore
              style={{
                // @ts-ignore
                "--vm-player-theme": "#e86c8b",
                "--vm-player-border-radius": "14px",
              }}
              autoplay={false}
              playsinline={true}
              muted={false}
              loop={true}
              controls={false}
              onVmPlay={() => {
                if (editedWidth === 0) {
                  setEditedWidth(1);
                } else {
                  // setEditedWidth(0)
                }
              }}
            >
              <Video
                className={classes.mainVideo}
                // @ts-ignore
                crossOrigin
                // poster="https://vod.api.video/vod/vi3gitF5AbbwQo2EXGUD7hhQ/thumbnail.jpg"
              >
                <source data-src="./project_0clip_0-2.mp4" type="video/mp4" />
              </Video>

              <DefaultUi noControls>
                <Scrim />

                <Controls fullWidth pin="topLeft">
                  <ControlSpacer />
                  <MuteControl />
                </Controls>

                <Controls pin="center" hideOnMouseLeave activeDuration={1000}>
                  <PlaybackControl
                    // @ts-ignore
                    style={{ "--vm-control-scale": 1.7, margin: "0 auto" }}
                  />
                </Controls>

                <Controls fullWidth pin="bottomLeft">
                  <ControlSpacer />
                  <TimeProgress />
                </Controls>
              </DefaultUi>
            </Player>
          </motion.div>
        </div>
        <motion.div
          className={classes.caption}
          style={{ overflow: "hidden" }}
          initial={{ width: editedWidth, opacity: editedWidth }}
          animate={{
            width: editedWidth ? "400px" : 0,
            opacity: editedWidth ? 1 : 0,
          }}
          layout
          transition={{
            type: "spring",
            duration: 1,
            // stiffness: 100,
          }}
        >
          <motion.div
            className={classes.textContainer}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "spring",
              duration: 0.8,
              delay: 1.8,
              // ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <svg
              id="Ebene_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 93.12 27.63"
              className={classes.imageSkillbox}
            >
              <defs></defs>
              <g>
                <path
                  className={classes.cls5}
                  d="M24.87,14.19c0-4.97,3.11-8.02,7.8-8.02,4.34,0,7.17,2.52,7.17,6.64h-3.18c-.03-2.33-1.54-3.74-3.9-3.74-2.7,0-4.37,1.92-4.37,5.13s1.64,5.13,4.25,5.13,4.03-1.38,4.06-3.68h3.18c0,4.15-2.96,6.57-7.39,6.57s-7.61-3.05-7.61-8.02Z"
                />
                <path
                  className={classes.cls5}
                  d="M41.79,17.49c0-2.36,1.67-4.21,5.82-4.21h5.76v-1.1c0-2.33-1.35-3.37-4.09-3.37-2.39,0-3.74,.91-3.74,2.64,0,.09,0,.35,.03,.6h-3.24c-.03-.22-.06-.63-.06-.91,0-3.11,2.7-4.97,7.14-4.97,4.72,0,7.48,2.23,7.48,6.32v9.47h-3.52c.06-.75,.16-2.17,.16-3.43h-.03c-.5,2.3-2.36,3.68-5.79,3.68s-5.91-1.7-5.91-4.72Zm11.57-1.79v-.28h-5.44c-2.01,0-2.89,.69-2.89,1.92,0,1.42,1.26,2.23,3.4,2.23,3.05,0,4.94-1.48,4.94-3.87Z"
                />
                <path
                  className={classes.cls5}
                  d="M60.35,.16h3.52V14.57h.03l8.37-8.15h4.56l-7.26,6.76,7.67,8.77h-4.78l-5.47-6.54-3.11,2.86v3.68h-3.52V.16Z"
                />
                <path
                  className={classes.cls5}
                  d="M93.12,14.88h-11.64c.19,2.99,1.86,4.59,4.5,4.59,2.26,0,3.81-1.01,3.96-2.83h3.18c-.12,3.46-2.96,5.57-7.2,5.57-4.91,0-7.99-3.02-7.99-7.89s3.08-8.15,7.99-8.15c4.4,0,7.2,2.61,7.2,7.45v1.26Zm-3.18-2.36c0-2.33-1.45-3.62-3.99-3.62s-3.96,1.26-4.37,3.84h8.37v-.22Z"
                />
              </g>
              <path
                className={classes.cls7}
                d="M13.83,.01V13.8L23.59,4.04S20.29,.01,13.83,.01Z"
              />
              <path
                className={classes.cls1}
                d="M4.08,4.06L13.83,13.81V.02S8.65-.5,4.08,4.06Z"
              />
              <path
                className={classes.cls4}
                d="M.01,13.8H13.8L4.04,4.04S.01,7.34,.01,13.8Z"
              />
              <path
                className={classes.cls6}
                d="M4.06,23.58L13.81,13.83H.02s-.52,5.18,4.05,9.75Z"
              />
              <path
                className={classes.cls2}
                d="M13.84,27.62V13.83S4.08,23.59,4.08,23.59c0,0,3.3,4.03,9.75,4.03Z"
              />
              <path
                className={classes.cls3}
                d="M23.58,23.57L13.84,13.82v13.79s5.18,.52,9.75-4.05Z"
              />
            </svg>
          </motion.div>
          <motion.div
            className={classes.textContainer}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: "spring",
              duration: 0.8,
              delay: 1.8,
              // ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <h2 className={classes.h2}>
              Want a page like this to sell your products, pitch ideas or get
              bookings?
            </h2>
          </motion.div>

          {editedWidth && (
            <motion.ul
              className={classes.container1}
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <motion.li key={1} className={classes.item1} variants={item}>
                <a
                  href="https://portal.cake.work/new-account"
                  className={classes.liButton}
                >
                  GET YOUR FREE CAKE PAGE
                </a>
              </motion.li>
              {/* <motion.li key={2} className={classes.item1} variants={item}>
                <button
                  className={classes.liButton2}
                  onClick={() => {
                    // @ts-ignore
                    window.location = "https://calendly.com/info-42200/15min";
                  }}
                >
                  BOOK A DEMO
                </button>
              </motion.li> */}
            </motion.ul>
          )}
        </motion.div>
      </div>

      <div className={classes.footer}>
        <a
          className={classes.footerLink}
          href="https://www.cake.work/privacy-policy"
        >
          Privacy
        </a>
        <a className={classes.footerLink} href="https://www.cake.work/imprint">
          Imprint
        </a>
        <a className={classes.footerLink} href="https://www.cake.work/terms">
          Terms
        </a>
        <a
          className={classes.footerLink}
          href="https://www.cake.work/cookie-policy"
        >
          Cookie policy
        </a>
      </div>
    </div>
  );
};

export default observer(VideoFlow);
