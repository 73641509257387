// import { action, makeObservable, observable } from "mobx";
// import { getEnv } from "../../../helpers/mobx-easy-wrapper";
// import PrezaElement from "./prezaElement";
// import { ELEMENT_TYPES } from "../../../helpers/enums";
// import {
//   IframeElementData,
//   ImageElementData,
//   OBJECT_FIT,
//   PLOT_MODE,
//   PLOT_THEME,
//   SLIDE_TEMPLATES,
//   TextElementData,
//   TEXT_ALIGN,
// } from "../../../types";
// import { nanoid } from "nanoid";
// import PlotParticipant from "./plotParticipant";

// import isEqual from "lodash/isEqual";
// import { SLIDES_COLUMNS } from "../../../helpers/constants";
// import { arrayMove } from "../../../helpers/helper";
// import { themeColors } from "./theme";

export class PlotStore {}
